.mainheader {
    padding: 10px 0px;
    box-shadow: 1px 1.7px 4px rgba(0, 0, 0, .3);
}
p {
    margin-bottom: 0px !important;
}
a {
    text-decoration: none !important;
}
.pagewidth {
    /* width: 80%; */
    max-width: 1100px;
    margin: 0px auto;
}
.dlrlogo {
    height: 55px;
}
.bodybg {
    background-color: #f8f8f8;
    padding: 15px 0px;
}
.mainbody {
    margin-top: 30px;
}
.bookhoteltitle {
    text-align: center;
    font-weight: 600;
    color: #3b3b3b;
    font-size: 20px;
    margin-bottom: 15px !important;
}
.titleheadbox {
    background-color: #1F212F;
    padding: 15px 25px;
    margin-top: 15px;
    cursor: pointer;
}
.titleheadbox-title {
    color: #fff;
    font-size: 19px;
    font-weight: 600;
}
.descriptionbox {
    background-color: #f8f8f8;
    padding: 20px 30px;
    border: 1px solid #1F212F;
    display: block;
}
.inputlabel {
    color: #3b3b3b;
    font-weight: 500;
    font-size: 15px;
    line-height: 45px;
}
.custominput {
    height: 45px;
}
.css-13cymwt-control, .css-t3ipsp-control {
    min-height: 45px !important;
}
.rowmargintop {
    margin-top: 12px !important;
}
.labelsubhead {
    font-size: 12px;
    color: #3b3b3b;
    font-weight: 500;
}
.adultsboxdiv {
    display: flex;
    flex-direction: row;
}
.plusbox {
    height: 40px;
    width: 40px;
    border: 1px solid #d9d9d9 !important;
    background: #f8f8f8;
    cursor: pointer !important;
    border-radius: 0px !important;
}
.plusbox:hover {
    background-color: #c6c3c3;
}
.adultinput {
    height: 40px;
    width: 40px;
    border-radius: 0px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.adultinput p {
    font-size: 14px;
    text-align: center;
}
.anotherroom {
    width: max-content;
    background-color: #f4f5f5;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 8px 20px;
    margin-top: 10px;
    cursor: pointer;
}
.anotherroom p {
    color: #e7b661;
    font-weight: 600;
    font-size: 14px;
}
.anotherroom:hover {
    background: -webkit-gradient(linear, left top, left bottom, from(#d9dddd), to(#c6c3c3));
}
.inputlabelroom {
    margin-top: 15px;
}
.iconstyles {
    color: red;
    font-size: 16px;
    line-height: 40px;
}
.closeicondiv {
    /* margin-left: 15px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    padding: 12px;
}
.adultsboxdiv1 {
    display: flex;
    flex-direction: row;
}
.labelsubhead1 {
    font-size: 11px;
    color: #3b3b3b;
    /* font-weight: 500; */
}
.childrensagebox {
    margin: 0px 5px;
    margin-bottom: 10px;
}
.childrensagebox select {
    width: 45px;
    height: 40px;
    border: 1px solid #d9d9d9 !important;
    background: #f8f8f8;
    border-radius: 0px !important;
    font-size: 12px;
}
.childrensagediv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.check-availability-btn {
    background-color: #e7b661;
    border: 1px solid #e7b661;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    height: 40px;
}
.check-availability-btn p {
    font-weight: 500;
    color: #fff;
    text-align: center;
    line-height: 40px;
}
.homebtnspinner {
    color: #fff;
    line-height: 40px;
    text-align: center;
    margin: 0px auto;
    top: 7px;
}
.check-availability-btn:hover {
    background-color: #f8f8f8;
}
.check-availability-btn:hover p {
    color: #e7b661;
}
.roomflex {
    display: flex;
    flex-direction: row;
    padding: 20px 20px;
    background-color: #e6e6e6;
}
.descriptionbox1 {
    padding: 0px;
}
.roomimg {
    height: 150px;
}
.room-pricediv {
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: 10px;
}
.room-titlediv {
    margin-left: 20px;
}
.room-title {
    color: #3b3b3b;
    font-size: 20px;
    font-weight: 700;
}
.room-userdiv {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}
.usericonstyles {
    font-size: 20px;
    margin-right: 5px;
}
.room-userchildrendiv {
    margin-left: 15px;
}
.usericonstyles1 {
    font-size: 16px;
    margin-right: 5px;
}
.room-sizetext {
    font-size: 14px;
    color: #3b3b3b;
    margin-top: 7px;
    font-weight: 700;
}
.room-sizetext span {
    font-weight: 500;
}
.originalprice {
    font-size: 14px;
    color: #aaa;
    font-weight: 500;
    text-align: right;
}
.offerpricepricediv {
    display: flex;
    flex-direction: row;
}
.ratefrom {
    font-size: 12px;
    color: #3b3b3b;
}
.offerprice {
    color: #e7b661;
    font-size: 21px;
    font-weight: 600;
    margin-top: -5px;
}
.ratepricediv {
    margin-left: 5px;
    margin-top: -25px;
}
.per-night {
    font-size: 12px;
    color: #3b3b3b;
    margin-left: 5px;
}
.per-night span {
    font-weight: 600;
}
.plustax {
    color: #55763d;
    font-size: 12px;
    text-align: right;
    margin-top: -5px;
}
.tabmaindiv {
    display: flex;
    flex-direction: row;
}
.tabmainbox {
    text-align: center;
    color: #7d7d7d;
    font-weight: 700;
    line-height: 40px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-right: 3px;
    background-color: #e6e6e6;
    width: 170px;
    border: 1px solid #e6e6e6;
    border-bottom: 0px;
    cursor: pointer;
}
.tabmainbox p {
    color: #191919;
    font-size: 14px;
}
.roomsdescdiv {
    margin-top: 2px;
    padding: 0px 20px;
}
.ratesbg {
    background-color: #f8f8f8;
    padding: 20px 0px;
}
.dealsdiv {
    display: flex;
    flex-direction: row;
}
.deal-title {
    font-size: 14px;
    color: #3b3b3b;
    font-weight: 700;
}
.tagicon {
    color: #d9534f;
    margin: 3px 5px 0px 10px;
    font-size: 14px;
}
.discount-title {
    color: #d9534f;
    font-size: 14px;
    font-weight: 700;
}
.ratesmaindiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}
.inclusivemaindiv {
    width: 50%;
}
.inclusiverow {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
}
.inclusiveicon {
    color: #3b3b3b;
    font-size: 14px;
    margin-right: 15px;
    margin-top: 3px;
}
.inclusivetext {
    color: #3b3b3b;
    font-size: 14px;
    font-weight: 500;
}
.weekdaytext {
    color: #3b3b3b;
    font-size: 15px;
    font-weight: 500;
    margin-top: 15px;
}
.viewdetails {
    margin-top: 10px;
    color: #e7b661;
    font-weight: 600;
    font-size: 15px;
    cursor: pointer;
    width: max-content;
}
.ratespricebooknowdiv {
    display: flex;
    flex-direction: row;
}
.booknowbtn {
    width: 135px;
    border-radius: 5px;
    background-color: #e7b661;
    border: 1px solid #e7b661;
    height: 40px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    cursor: pointer;
}
.booknowbtn p {
    color: #fff;
    text-align: center;
    font-weight: 600;
    font-size: 15px;
}
.booknowbtn:hover {
    background-color: #fff;
}
.booknowbtn:hover p {
    color: #e7b661;
}
.amenitiestext {
    color: #3b3b3b;
    font-size: 15px;
    font-weight: 500;
    /* margin-top: 15px; */
}
.enhancemaindiv, .guestinfomaindiv {
    padding: 20px 20px;
}
.hillviewimg {
    width: 100%;
}
.enhancebox {
    margin-bottom: 30px;
}
.enhanceboxdiv {
    padding: 15px 20px 30px;
    background-color: #fff;
}
.enhancemrpdiv {
    margin-left: 10px;
}
.enhancemrptext {
    font-size: 14px;
}
.enhancetitle {
    color: #3b3b3b;
    font-size: 16px;
    font-weight: 500;
}
.enhanceskipdiv {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.skiptext {
    color: #e7b661;
    font-size: 15px;
    font-weight: 600;
    line-height: 40px;
    cursor: pointer;
}
.enhancebtn {
    width: max-content;
    padding: 0px 20px;
}
.guestinfomaindiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.guestinfoformdiv {
    width: 53%;
}
.guestcheckindiv {
    width: 43%;
}
.guestinput {
    width: 100%;
    height: 45px;
    margin-bottom: 12px;
    border: 1px solid #bbc7cc !important;
}
.guestinput::placeholder {
    color: #c6c3c3 !important;
    font-size: 14px;
}
.guesttextarea {
    height: 80px;
}
.guestpreferencediv {
    margin-top: 5px;
    border: 1px solid #bbc7cc;
    background-color: #fff;
    padding: 15px 15px;
    border-radius: 5px;
}
.guestpreferencerow {
    /* display: flex;
    flex-direction: row; */
}
.prefertext {
    font-weight: 700;
    cursor: pointer;
    color: #3b3b3b;
    font-size: 13px;
    margin-left: 10px;
    margin-top: -3px;
    position: relative;
    bottom: 2px;
}
.paynowrow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 20px;
}
.pay-now-text {
    color: #3b3b3b;
    font-size: 12px;
    font-weight: 500;
}
.pay-now-price {
    color: #05db5c;
    font-weight: 700;
    margin-top: -5px;
    font-size: 17px;
}
.paynowrow1 {
    margin-top: 7px;
}
.pay-now-price1 {
    color: #3b3b3b;
    font-size: 13px;
}
.guestorrow {
    display: flex;
    flex-direction: row;
    margin: 0px 0px;
}
.paymentoptionhr {
    margin-left: 10px;
    margin-right: 10px;
    border-style: dashed;
    border-color: #333;
    border-width: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}
.paymentortext {
    padding: 0px 5px;
    color: #3b3b3b;
    font-size: 12px;
}
.inputradiobutton {
    margin-top: 15px;
}
.bookyourstaytext {
    color: green;
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
    font-weight: 500;
}
.bycompletingtext {
    color: #3b3b3b;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
}
.termstext {
    color: #e7b661;
    cursor: pointer;
    font-weight: 600;
}
.booknowbtn1 {
    margin: 20px auto;
    align-self: center;
    height: 45px;
    width: 250px;
}
.cardsarr {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: -5px;
}
.cardimg {
    height: 30px;
    margin: 0px 3px;
}
.cardimg1 {
    height: 17px;
    margin-top: 7px;
}
.checkinrow {
    border-top: 1px dashed #d9d9d9;
    border-bottom: 1px dashed #d9d9d9;
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
    margin-bottom: 15px;
}
.checkintext {
    color: #636363;
    font-size: 12px;
    font-weight: 400;
}
.checkindate {
    color: #3b3b3b;
    font-size: 17px;
    margin-top: 5px;
}
.checkinbox {
    width: 50%;
    border-right: 1px dashed #d9d9d9;
}
.checkoutbox {
    padding-left: 20px;
}
.guestdescrow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}
.guestlabel {
    font-weight: 500;
    font-size: 12px;
    color: #636363;
    padding-top: 1px;
}
.guestvalue {
    color: #191919;
    font-size: 15px;
    font-weight: 600;
}
.roombg {
    background-color: #bdbdbd;
    border-radius: 0 5px 5px 0;
    padding: 1px 5px 1px;
    height: max-content;
    margin-left: -5px;
    color: #fff;
}
.grandtotalbg {
    border: 1px solid #d9d9d9;
    background-color: #fff !important;
    padding: 5px 20px 10px;
    margin-top: 10px;
}
.grandtotalbghr {
    border-top: 1px dashed #333;
    margin: 10px 0px 0px;
}
.grandtotaltext {
    font-size: 16px;
    color: #191919;
    font-weight: 500;
    padding: 6px 0;
}
.inrtext {
    margin-left: auto;
    font-size: 24px;
    color: #191919;
}
.mrptext {
    font-size: 15px;
    color: #aaa;
    padding-top: 10px;
    margin: 0px 10px;
}
.bookyourstaytext span {
    color: #05db5c;
    font-size: 18px;
}
.guestangleright {
    background-color: #e7b661;
    color: #fff;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    margin-right: 5px;
    font-size: 10px;
    padding: 2px;
    position: relative;
    top: 5px;
}
.guestfooterborder {
    border-top: 1px solid #d9d9d9;
    margin-top: 10px;
}
.guestfooterrow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
}
.verify1img {
    height: 45px;
    margin: 0px 5px;
}
.verify2img {
    height: 22px;
    margin-top: 12px;
}
.verify3img {
    height: 35px;
}

/* admin */

.webloginBox {
    width: 30%;
    margin: 0px auto;
    display: block;
    padding: 25px 30px;
    border-radius: 15px;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2);
    margin-top: 5%;
}
.loginBoxMaindiv {
    height: 100vh;
}
.webcustom-btn {
    background-color: #e7b661 !important;
    margin-top: 10px;
    width: 100%;
    border: 1px solid #e7b661 !important;
    height: 42px;
}
.webloginBox img {
    margin: 0px auto;
    display: block;
    height: 70px ;
}
.spinnerCenter {
    margin: 0px auto;
}
.sidebar-div {
    display: inline-block;
    height: 100%;
    position: fixed;
    width: 20%;
    z-index: 1;
}
.webadmin-sidebarmain {
    background-color: #f8f8f8;
}
.sidebar-main {
    height: 100vh;
    padding: 50px 20px;
}
.main-layout {
    display: inline-block;
    margin-left: 20%;
    width: 80%;
}
.mainDiv {
    margin-top: 25px;
    padding: 0 30px;
}
.webadmin-sidebarmain .sidebarLogo {
    display: block;
    height: 55px;
}
.webadmin-sidebarmain .sidebarMenuDiv {
    margin-top: 40px;
}
.sidebarMenuDiv ul {
    list-style: none;
    padding-left: 0;
}
.webadmin-sidebarmain .sidebarMenuDiv li {
    border-bottom: 1px solid #000;
    margin: 0;
    padding: 5px 0;
}
.sidebarMenuDiv li {
    color: #000;
    font-size: 32px;
    font-weight: 700;
    line-height: 35px;
    margin: 30px 0;
    text-align: left;
}
.sidebarMenuDiv li a {
    color: #000;
    text-decoration: none;
}
.webadmin-sidebar-maintitle {
    font-size: 17px;
    font-weight: 500;
}
.webadmin-sidebar-maintitlediv {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.webadmin-sidebarmain .dropdown-container {
    background-color: initial;
    padding-left: 20px;
    padding-top: 0;
}
.webadmin-sidebar-subtitle {
    border-bottom: .5px solid #000;
    display: block;
    font-size: 16px;
    font-weight: 500;
    padding: 4px 0;
}
.dropdown-container .webadmin-sidebar-subtitle:last-child {
    border-bottom: 0;
}
.sidebaricons {
    height: 15px;
    margin-top: 10px;
    width: 15px;
}
.sidebarMenuDiv ul li:last-child {
    border-bottom: 0;
}
.overlay-main {
    height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      position: fixed;
      z-index: 2;
  }
  .spinnerCenter1 {
    margin: 0px auto;
    margin-top: 20%;
  }
  .oneplaceHead {
    border-bottom: 2px solid #000;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 10px !important;
}
.tablebuttondiv {
    display: flex;
    justify-content: flex-end;
}
.tablebuttondivpage {
    margin-bottom: 20px;
}
.btndiv {
    border-radius: 10px;
    padding: 10px 15px;
}
.webadminmaindiv {
    background-color: #1F212F;
}
.btndiv p {
    color: #fff;
    font-size: 15px;
    font-weight: 600;
}
.webadmin-modalbtn {
    background-color: #e7b661;
    border: 1px solid #e7b661;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 25px;
}
.webadmin-modalbtn p {
    font-weight: 500;
    color: #fff;
    text-align: center;
    line-height: 40px;
}
.tabinput {
    height: 40px;
}
.webadmin-modalmainbody .css-13cymwt-control {
    min-height: 40px !important;
    height: 40px;
}
.plusminusdiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
}
.roomsplusicondiv {
    background-color: #28a745;
    border-radius: 5px;
    height: 35px;
    width: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}
.roomplusicon {
    font-size: 14px;
}
.roomsdescroom {
    border: 1px solid #7d7d7d;
    padding: 20px 20px;
    border-radius: 10px;
    margin-bottom: 15px;
}
.updateroomimgdiv {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
}
.updateroomimgbox {
    position: relative;
    margin: 0px 10px;
}
.updateroomimgbox img {
    /* width: 100px; */
    height: 30px;
}
.roomimagesicon {
    position: absolute;
    top: -10px;
    right: -8px;
    cursor: pointer;
}
.ck-editor__editable_inline  {
    min-height: 100px;
}
.ratesarrdiv {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px dashed #7d7d7d;
}
.htmlcontent table {
    width: 100%;
}
.htmlcontent tr {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: unset !important;
    border-bottom: 0px ;

}

.htmlcontent tr td {
    color: #3b3b3b;
    font-size: 15px;
    font-weight: 500;
}

.htmlcontent ul {
    list-style-type: disclosure-closed;
    padding-left: 1rem;
}

.htmlcontent ul li {
    margin-bottom: 8px;
    
}

.guestfaretablerow .guestlabel {
    line-height: 22px;
    max-width: 60%;
}
.guestfaretablerow .guestvalue {
    margin-top: 10px;
}

.viewbookingbox {
    border: 1px dashed #c6c3c3;
    border-radius: 10px;
    padding: 10px 15px;
}
.viewbookingbox .checkinrow {
    border: 0px;
    margin-bottom: 0px;
    padding: 0px;
}
.viewbookinguserdiv {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}
.viewbookinguserdiv .checkintext {
    font-weight: 600;
    width: 40%;
    font-size: 14px;
}
.viewbookinguserdiv .checkintextvalue {
    font-weight: 400;
    width: 50%;
}
.viewboxhead {
    color: #191919;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px !important;
}
.viewroomsbookeddiv {
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px dashed #c6c3c3;
}
.viewbookingbox .viewroomsbookeddiv:last-child {
    border-bottom: 0px;
    margin-bottom: 0px;
}
.viewbookingoxpayment .checkintextvalue {
    margin-left: auto;
    width: unset;
}
.viewbookingoxpayment hr {
    margin: 12px 0px;
}
.booknowadminpaymentbtn {
    height: 42px;
    margin: 10px auto;
    width: 100%;
}
.promotextbox {
    background-color: #dff0d8;
    border: 1px solid #d6e9c6;
    border-radius: 5px;
    width: 80%;
    float: right;
    padding: 20px 20px;
    color: #3c763d;
}
.promotextbox ul {
    margin-bottom: 0px;
}
.promotextbox.descriptionbox {
    margin-top: 20px;
    background-color: #fff;
}
.promotextbox .htmlcontent ul li {
    margin-bottom: 8px;
    font-size: 14px;
}
.custom-width-modal-room .modal-content {
    border-top: 5px solid #e7b661;
    border-bottom: 5px solid #e7b661;
}
.custom-width-modal-room .modal-content .modal-title.h4 {
    font-size: 16px !important;
}
.canceltitle {
    font-size: 14px;
    font-weight: 600;
    color: #3b3b3b;
}
.canceldescription {
    font-size: 13px;
    color: #3b3b3b;
    line-height: 17px;
    margin-top: 10px;
}
.viewdetailspayblock {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    padding: 5px 20px;
    width: 85%;
    margin: 0px auto;
}
.viewdetailspayrow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 8px 0px;
}
.viewdetailspaykey {
    color: #636363;
    font-size: 12px;
}
.viewdetailspayrowhr {
    border-top: 2px solid #d9d9d9;
    margin: 8px 0px;
}
.custom-width-modal-room .modal-content {
    background-color: #f8f8f8;
}
.viewdetailspaykeytotal {
    color: #000;
    font-size: 14px;
}
.enhancehtmlcontent p {
    font-size: 13px;
}
.carousalmaindiv {
    padding: 0px 50px;
}
  /* for desktop */
@media only screen and (min-width: 768px) {
    .deskhide {
      display: none;
    }
  }
  /* mobile */
  @media only screen and (max-width: 767px) {
    .pagewidth{
        width: 90%;
      }
     .mobhide {
      display: none;
     }
     .dlrlogo {
        height: 35px;
    }
    .mainheader {
        padding: 5px 0px;
        width: 100%;
    }
    .mainbody {
        margin-top: 15px;
    }
    .bookhoteltitle {
        font-size: 18px;
    }
    .titleheadbox {
        padding: 10px 15px;
    }
    .titleheadbox-title {
        font-size: 16px;
    }
    .descriptionbox {
        padding: 15px 15px;
    }
    .inputlabel {
        line-height: 30px;
        font-size: 15px;
        text-align: left !important;
    }
    .rowmargintop {
        margin-top: 8px !important;
    }
    .childrensagediv {
        margin-top: 10px;
    }
    .childrensagebox {
        margin: 0px 15px 0px 0px;
    }
    .inputlabelroom {
        margin-top: 5px;
    }
    .promotextbox {
        width: 100%;
        margin-top: 20px;
    }
    .roomflex {
        display: block;
        padding: 0px 0px;
        background-color: unset;
    }
    .roomimg {
        height: unset;
        width: 100%;
    }
    .room-titlediv {
        margin-left: 0px;
        margin-top: 10px;
        display: inline-block;
    }
    .room-pricediv {
        display: inline-block;
        height: max-content;
        margin-right: 0px;
        float: right;
        margin-top: 50px;
    }
    .room-title {
        font-size: 16px;
    }
    .usericonstyles {
        font-size: 16px;
    }
    .usericonstyles1 {
        font-size: 13px;
    }
    .roomsdescdiv {
        margin-top: 15px;
        padding: 0px 0px;
        margin-bottom: 30px;
    }
    .tabmainbox {
        width: 33.3%;
    }
    .tabmainbox p {
        font-size: 12px;
    }
    .dealsdiv {
        display: block;
    }
    .tagicon {
        display: inline-block;
        margin: 3px 5px 0px 0px;
        font-size: 12px;
    }
    .discount-title {
        display: inline-block;
        font-size: 12px;
    }
    .ratesmaindiv {
        display: flex;
        margin-top: 5px;
    }
    .htmlcontent tr {
        display: block;
    }
    .htmlcontent tr td {
        width: 100%;
        display: block;
    }
    .htmlcontent ul {
        margin-bottom: 0px;
    }
    .htmlcontent ul li {
        font-size: 12px;
        margin-bottom: 5px;
    }
    .inclusivemaindiv {
        display: inline-block;
    }
    .ratespricebooknowdiv {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .offerprice {
        font-size: 18px;
        margin-top: -3px;
    }
    .offerpricepricediv {
        text-align: right;
        justify-content: flex-end;
    }
    .booknowbtn {
        width: 110px;
        height: 35px;
        margin-left: 0px;
        margin-top: 10px;
    }
    .table {
        margin-bottom: 0px !important   ;
    }
    .viewdetails {
        margin-top: 0px;
    }
    .carousalmaindiv {
        padding: 0px 10px;
    }
    .room-sizetext {
        font-size: 12px;
    }
    .enhancemaindiv, .guestinfomaindiv {
        padding: 0px 0px;
    }
    .skiptext {
        margin-right: 15px;
    }
    .enhanceskipdiv {
        justify-content: space-between;
    }
    .enhancebtn {
        width: max-content;
        height: 40px;
        margin-top: 0px;
    }
    .guestinfomaindiv {
        flex-direction: column;
    }
    .guestinfoformdiv {
        width: 100%;
    }
    .guestcheckindiv {
        width: 100%;
    }
    .grandtotalbg {
        margin-top: 15px;
    }
    .guestpreferencediv {
        margin-top: 15px;
    }
    .booknowbtn1 {
        width: 100%;
        height: 40px;
        margin-top: 15px;
    }
    .cardimg {
        height: 25px;
    }
    .cardimg1 {
        height: 13px;
    }
    .verify1img {
        height: 35px;
    }
    .verify2img {
        height: 17px;
    }
    .verify3img {
        height: 28px;
    }
    .custom-width-modal-room .modal-content .modal-title.h4 {
        font-size: 14px !important;
    }
    .btn-close {
        height: 8px !important;
        width: 8px !important;
    }
    .modal-dialog {
        width: 90%;
        margin: 30px auto !important;
    }
    .viewdetailspayblock {
        width: 100%;
        margin-bottom: 15px;
    }
    
  }